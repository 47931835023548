import React, { useState, useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { Grid, Box } from '@material-ui/core';
import SectionContainerLayoutWithFilter from '../../components/section-container-layout-with-filter';
import CarouselVideoContainer from '../../components/video-carousel';
import SectionContainerLayout from '../../components/section-container-layout/section-container-layout';
import colors from '../../constants/colors';

const DigitalSeries = ({ pageContext: { _digitalSeries } }) => {
  return (
    <>
      <SEO lang='en' title='Digital Series' />
      <Layout>
        <Grid container>
          <Grid item xs={12}>
            <SectionContainerLayoutWithFilter title='DIGITAL SERIES' isViewAll baseLink='/digital-series'>
              <CarouselVideoContainer
                videoUrl={
                  _digitalSeries.file[0].type === 'video/mp4'
                    ? `${process.env.GATSBY_HLS_URL}${_digitalSeries.file[0].code}/stream.m3u8`
                    : `${process.env.GATSBY_HLS_URL}${_digitalSeries.file[1].code}/stream.m3u8`
                }
                showControls
                autoPlay
              />
            </SectionContainerLayoutWithFilter>
          </Grid>
          <Grid item xs={12}>
            <SectionContainerLayout title='DETAILS'>
              <Box fontSize='body1.fontSize' style={{ color: colors.text.secondary.dark }}>
                <div dangerouslySetInnerHTML={{ __html: _digitalSeries.details }} />
              </Box>
            </SectionContainerLayout>
          </Grid>
        </Grid>
      </Layout>
    </>
  );
};

export default DigitalSeries;
