import React from 'react';
import ReactPlayer from 'react-player';

const CarouselVideoContainer = ({ videoUrl, showControls, autoPlay, loop, enablePip, isMuted }) => {
  return (
    <ReactPlayer
      url={videoUrl}
      controls={showControls}
      playing={autoPlay ? true : false}
      loop={loop ? true : false}
      pip={enablePip ? true : false}
      width='100%'
      height='100%'
      muted={isMuted}
    />
  );
};

export default CarouselVideoContainer;
